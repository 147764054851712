<template>
  <section class="inf-p c-lp" v-if="!addSlideMode">
    <h3 v-if="cardArray.length" class="c-title">Vote ou propose une activité</h3>
    <div v-if="cardArray.length"
         class="slide-container">
      <agile ref="carousel"
             :options="myOptions"
             class="desc">
        <div class="slide" v-for="(card, i) in suggestionStatus" :key="i">
          <div class="slide-wrapper"
               @click="$refs.carousel.goTo(i)"
               :style="{'background-image': card.picture ? `url(${card.picture})` : 'null'}">
            <div class="slide-head"></div>
            <div class="slide-text">
              <div class="slide-wrapper-header">
                <h3 v-if="card.title" class="slide-title">{{ card.title  }}</h3>
                <p>{{ card.type }}</p>
              </div>
              <div class="date-div">
                <h4 v-if="card.starting_date"> Le {{ formatDateonly(card.starting_date ) }} à {{ card.starting_time }}</h4>
              </div>
              <h4 v-if="card.address">{{ card.address }}</h4>
              <p v-if="card.description" class="scroll">{{ card.description }}</p>
              <h1> Déjà {{card.votes.length }} vote(s) </h1>
              <div class="flex justify-center items-center pt-3">
                <button @click="addToVoter(card)"
                        class="relative bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                        style="font-size: 14px">
                  Voter
                </button>
              </div>
              <div class="flex justify-center items-center pt-3">
                <button @click="addSlideMode = !addSlideMode"
                        class="relative bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                        style="font-size: 14px">
                  Suggérer une activité
                </button>
              </div>
            </div>
            <div class="slide-footer absolute">
              <ShareNetwork
                class="facebook-share-link hover:bg-white"
                network="facebook"
                url="https://www.fernelmove.be/suggestion"
                title=""
                description=""
                quote=""
                hashtags="fernelmove"
              ></ShareNetwork>
            </div>
          </div>
        </div>
      </agile>
    </div>
    <div v-else class="bienvenue">
      Bienvenue sur Fernelmove.be
      <div class="flex justify-center items-center pt-3">
        <button @click="addSlideMode = !addSlideMode"
                class="relative bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                style="font-size: 14px">
          Suggérer une activité
        </button>
      </div>
    </div>
  </section>

  <section v-if="cardArray.length && !addSlideMode"
           class="mob-slider">
    <agile class="agile-mobile mob"
           :slides-to-show="1"
           :dots="false"
           :nav-buttons="false">
      <div class="slide-mob bg-indigo-600 bg-opacity-0"
           v-for="(mobCard, iMobCard) in suggestionStatus"
           :key="iMobCard"
           :style="{'background-image': mobCard.picture ? `url(${mobCard.picture})` : 'null'}">
        <div class="mob-s__wrapper">
          <div class="mob-s__wrapper-header">
            <div class="title">
              <h1 class="pb-5">Vote ou propose une activité</h1>
            </div>
            <h3 v-if="mobCard.title" class="slide-title">{{ mobCard.title }}</h3>
            <p>{{ mobCard.type }}</p>
          </div>
          <div class="date-div">
            <h4 v-if="mobCard.starting_date"> Le {{ formatDateonly(mobCard.starting_date ) }} à {{ mobCard.starting_time  }}</h4>
          </div>

          <h4 v-if="mobCard.address">{{ mobCard.address }}</h4>
          <p v-if="mobCard.description" class="scroll">{{ mobCard.description }}</p>
          <h1> Déjà {{mobCard.votes.length }} vote(s) </h1>

          <div class="flex justify-center items-center pt-3 mob-s__btn">
            <button @click="addToVoter(mobCard)"
                    class="relative bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                    style="font-size: 14px">
              Voter
            </button>
          </div>
          <div class="flex justify-center items-center pt-3 mob-s__btn">
            <button @click="addSlideMode = !addSlideMode"
                    class="relative bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                    style="font-size: 14px">
              Suggérer une activité
            </button>
          </div>

          <div class="slide-footer absolute">
            <ShareNetwork
              class="facebook-share-link mob hover:bg-white"
              network="facebook"
              url="https://www.fernelmove.be/suggestion"
              title=""
              description=""
              quote=""
              hashtags="fernelmove"
            ></ShareNetwork>
          </div>

        </div>
      </div>
    </agile>
  </section>

  <section class="c-lp"
           v-if="addSlideMode">
    <add-slide></add-slide>
  </section>
</template>

<script>
import { VueAgile } from '@/assets/vue-agile'
import axios from 'axios'
import AddSlide from '@/components/main/add-slide'
import moment from "moment";

export default {
  name: 'Suggestion',
  components: {
    agile: VueAgile,
    AddSlide
  },
  data () {
    return {
      cardArray: [],
      addSlideMode: false,
      myOptions: {
        navButtons: false,
        slidesToShow: 3,
        swipeDistance: 100,
        infinite: true,
        centerMode: true
      }
    }
  },
  async created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    await axios.post(`/api/activity?${query}`, {
      activity: 'suggestion'
    })
      .then(r => {this.cardArray = r.data.activity})
      .catch((e) => {
        localStorage.removeItem('token')
        this.$router.push('/login')
      })
  },
  methods: {
    formatDateonly (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    formatTimeOnly (value) {
      if (value) {
        return moment(String(value)).format('HH:MM')
      }
    },
    async addToVoter (card) {
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      await axios.put(`/api/activity/voter/${card._id}?${query}`)
        .then((res)=>{
          if(res.data.success){
            this.$toast.show('Votre vote a bien été ajouté', {
                type: "success",
                duration:2500
            })
            card.votes.push(1)
          }else{
            this.$toast.show('vous avez annulé votre vote', {
                type: "error",
                duration:2500
            })
            card.votes.shift()
          }
        })
        .catch(e => console.log(e))
    }
  },
  computed: {
    suggestionStatus: function () {
      return this.cardArray.filter(card => {
        return card.suggestionStatus
      })
    }
  }
}
</script>

<style scoped>
  .bienvenue{
    text-align: center;
    color: red;
    font-family: acier-bat-noir, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    margin-top: 7px;
  }
  .scroll{
    height:100px;
    overflow:auto;
  }

  .deleteComment {
    cursor:pointer;
    position: absolute;
    right: 15px;
  }

  .date-div {
    width:100%;
    margin-bottom : 0;
  }

  .mob-slider {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100vh;
    display: none;
  }

.close-chat-btn {
  width: 27px;
  background: white;
  color: white;
  display: block;
  padding: 0.3rem;
  border-radius: 20px;
  cursor: pointer;
  margin: auto;
}

  .send-message {
    max-width: 30px;
    max-height: 30px;
    width: 100%;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .agile-mobile {
    width: 100%;
    height: 100vh;
  }

  .mob-s__wrapper {
    color: white;
    background: rgba(0,0,0,0.5);
    border-top: 1px solid #46505e;
    border-bottom: 1px solid #46505e;
    padding: 2rem 1rem;
    display: flex;
    width: 100%;
    align-self: flex-end;
    margin-bottom: 40%;
    position: relative;
    flex-direction: column;
    min-height: 68%;
    max-height: 68%;
  }
  .mob-s__wrapper-header h3 {
    background-color: white;
  }
  .mob-s__wrapper-header p {
    background-color: red;
    font-weight: 700;
  }
  .slide-wrapper-header h3 {
    background-color: white;
    text-align: center;
  }
  .slide-wrapper-header p {
    background-color: red;
    font-weight: 700;
    text-align: center;
  }
  h1 {
    text-align: center;
    color: white;
    font-family: acier-bat-noir, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    /* text-underline-offset: 6px;
    text-overline-offset: 6px; */
    margin-top: 7px;
  }

  .mob-s__btn {
    flex: 1 1 auto;
  }

  .slide-mob {
    color: #000;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }

  .slide-wrapper {
    border: 1px solid #46505e;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }

  .slide-container {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 120px;
    overflow-x: hidden;
  }

  .slide-container.mobile {
    display: none;
  }

  .slide-footer {
    right: 10px;
    bottom: 10px;
    display: flex;
    /*background-color: #fff;*/
    border-radius: 6px;
    padding: 2px;
  }

  .slide-text {
    color: white;
    background: rgba(0,0,0,0.5);
    border-top: 1px solid #46505e;
    border-bottom: 1px solid #46505e;
    padding: 1rem;
    margin: 4rem 0;
    /*background-color: #fff;*/
  }

  .slide-text > p {
    font-size: 14px;
  }

  .slide-text {
    text-align: left;
  }

  .slide-text > h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .come-back {
    position: relative;
  }

  .come-back > svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    transform: scale(-1, 1);
  }

  ::v-deep .agile__dot.agile__dot--current,
  ::v-deep .agile__dot {
    width: 15px;
    height: 15px;
    margin: 0 .5rem;
    border-radius: 50%;
    border: 2px solid #46505e;
  }

  ::v-deep .agile__dot.agile__dot--current {
    background-color: #46505e;
  }

  .cb-btn-chat {
    font-size: 12px;
    right: -100%;
    cursor: pointer;
  }

  .close-chat-btn {
    width: 27px;
    background: white;
    color: white;
    display: block;
    padding: 0.3rem;
    /*right: 15px;*/
    /*top: 20px;*/
    border-radius: 20px;
    cursor: pointer;
    margin: auto;
  }

  .chat {
    height: 340px;
    overflow: auto;
    background: rgba(0, 0, 0, 0.5);
    padding: 0 .5rem;
    border-radius: 6px 6px 0 0;
    position: relative;
  }
  .chat-input {
    background: rgba(0,0,0,0.5);
    border-radius: 0 0 6px 6px;
  }

  .chat-input > input {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
  }

  .chat-user-icon {
    max-width: 30px;
    max-height: 30px;
    width: 100%;
  }

  .chat-item {
    border: 1px solid black;
    border-radius: 6px;
    margin: 0.5rem 0;
  }

  .chat-user-name {
    font-size: 14px;
  }

  .chat-user-comment {
    font-size: 12px;
    padding-bottom: 10px;
  }

  .chat-item:nth-of-type(odd) {
    background-color: antiquewhite;
    color: black;
  }

  .chat-item:nth-of-type(even) {
    background-color: indianred;
    color: white;
  }

  @media screen and (max-width: 2560px) {
    .slide-container {
      max-width: 900px;
    }

    ::v-deep .slide.agile__slide {
      width: 300px!important;
    }

    .send-input {
      max-width: 250px;
    }

    .cb-btn {
      margin-bottom: 20px;
    }

    .cb-btn-chat {
      right: initial;
      bottom: -10px;
    }

  }

  @media screen and (max-width: 991px) {
    ::v-deep .slide-mob.agile__slide.agile__slide--active {
      transform: scale(1) translateY(0)!important;
    }

    .slide-container {
      max-width: 735px;
    }

    ::v-deep .slide.agile__slide {
      width: 245px!important;
    }

    .send-input {
      max-width: 195px;
    }
  }

  @media screen and (min-width: 767px) {
    ::v-deep .agile__slide--active {
      transform: scale(1.2) translateY(-12%)!important;
      z-index: 2;
    }

    .slide-wrapper {
      min-height: 470px;
      position: relative;
      z-index: 1;
    }

    ::v-deep .agile__list {
      overflow: initial!important;
    }

    ::v-deep .agile__actions {
      padding-top: 2rem;
    }

    ::v-deep .slide.agile__slide,
    ::v-deep .agile__slides {
      min-height: 470px!important;
    }
  }

  @media screen and (max-width: 767px) {
    .desc {
      display: none;
    }

    .mob-slider {
      display: block;
    }

    .c-title {
      display: none;
    }

    ::v-deep .agile__track {
      height: 100vh;
    }

    ::v-deep .agile__list {
      height: 100vh;
    }

    .cb-btn-chat {
      right: -100%;
      bottom: initial;
    }

    .chat,
    .chat-input {
      max-width: 365px;
      margin: 0 auto;
      width: 100%;
    }

    .send-input {
      max-width: 350px;
    }
  }

  @media screen and (max-width: 420px) {
    .chat,
    .chat-input {
      max-width: 280px;
      margin: 0 auto;
      width: 100%;
    }

    .send-input {
      max-width: 265px;
    }
  }
</style>
