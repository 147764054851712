<template>
  <section>
    <div id="map">
        <div  @click="visibilityFilter" class="visibilityFilter">-</div>
        <div id='filters'>
            <div>
                <input @click="filterActivity" type="checkbox" id="filterActivity" checked>
                <label for="activity" style='font-weight: bold;color:red'>activités</label>
            </div>
            <div>
                <input @click="filterGreen" type="checkbox" id="filterGreen" checked>
                <label for="green" style='font-weight: bold;color:green'>lieux de convivialité</label>
            </div>
            <div>
                <input @click="filterPurple" type="checkbox"  id="filterPurple">
                <label for="blue" style='font-weight: bold;color:purple'>écoles</label>
            </div>
            <div>
                <input @click="filterBlue" type="checkbox" id="filterBlue" checked>
                <label for="blue" style='font-weight: bold;color:blue'>livres</label>
            </div>
            <div>
                <input @click="filterBlack" type="checkbox" id="filterBlack" checked>
                <label for="black" style='font-weight: bold;color:black'>evenements</label>
            </div>
            <div>
                <input @click="filterPink" type="checkbox" id="filterPink">
                <label for="blue" style='font-weight: bold; color:pink'>creches</label>
            </div>
            <div>
                <input @click="filterOrange" type="checkbox" id="filterOrange">
                <label for="orange" style='font-weight: bold;color:orange'>bâtiments communaux</label>
            </div>
            <div>
                <input @click="filterGrey" type="checkbox" id="filterGrey" >
                <label for="grey" style='font-weight: bold;color:grey'>salles communales</label>
            </div>
        </div> 
        <img @click="visibilityDirection" class="visibilityDirection" src="/img/navigation.png" alt="navigation">
    </div>
  </section>
</template>

<script>
  import mapboxgl from "mapbox-gl";
  import "mapbox-gl/dist/mapbox-gl.css";
  import { onMounted } from "vue";
  import axios from 'axios';
  import moment from 'moment';

  export default {
    name: 'MapPage',
    data() {
        const geojson = {}

        const url_convivialite = "https://www.odwb.be/api/records/1.0/search/?dataset=lieux-de-convivialite-fernelmont&rows=25"
        const url_ecoles = "https://www.odwb.be/api/records/1.0/search/?dataset=ecoles-de-fernelmont&rows=25"
        const url_livres = "https://www.odwb.be/api/records/1.0/search/?dataset=boites-a-livres&rows=25"
        const url_salles_communales = "https://www.odwb.be/api/records/1.0/search/?dataset=salles-communales-et-autres&q=&rows=50"
        const url_creches = "https://www.odwb.be/api/records/1.0/search/?dataset=creches-accueillantes-fernelmont&q=&rows=25"
        const url_batiments_com = "https://www.odwb.be/api/records/1.0/search/?dataset=batiments-communaux-fernelmont&q=&rows=50"
        const url_events = "https://www.odwb.be/api/records/1.0/search/?dataset=evenements-annuels-commune-fernelmont&q=&rows=25"
        
        class Point {
            constructor(coordinates, title, description) {
                this.geometry = { coordinates: [coordinates] };
                this.properties = {
                    title: title,
                    description: description
                }
            }
        }
        
        onMounted(() => {            
            const my_marker = () => {
                let me = {
                    geometry: {
                        coordinates: [4.986931618847292, 50.55097608920171]
                    }
                }
                try {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        me.geometry.coordinates = [position.coords.longitude, position.coords.latitude]
                    })
                    setTimeout(() => {
                        const marker = document.createElement('div');
                        marker.className = 'my_marker';
                        marker.style = `background-image: url(${this.avatar})`
                        // make a marker for each feature and add to the map
                        new mapboxgl.Marker(marker)
                            .setLngLat(me.geometry.coordinates)
                            .addTo(map);

                        map.flyTo({
                            center: me.geometry.coordinates,
                            essential: true
                        })
                    }, 200);

                    return me
                } catch {
                    console.log('error')
                }
            }
            const addMarker = (lieux, color) => {
                lieux.forEach(lieu => {
                    const marker = document.createElement('div');

                    if (color == 'activity') {
                        marker.className = 'my_activity';
                        marker.style = `background-image: url(${lieu.picture}); border-radius: 50%;`
                    } else {
                        marker.className = color;
                        marker.style = `cursor: pointer; border-radius: 50%;border: 9px solid ${color};width: 8px;height: 8px; visibility:${
                            color == 'orange' || 
                            color == 'grey' || 
                            color == 'purple' || 
                            color == 'pink' 
                            ? "hidden" : "visible"
                        }`
                    }
                    new mapboxgl.Marker(marker)
                        .setLngLat(lieu.geometry.coordinates[0])
                        .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
                        .setHTML(`
                            <h3>${lieu.properties.title}</h3>
                            ${lieu.properties.description}
                        `))
                        .addTo(map);

                })
                return lieux[0]
            }
            const upcomingActivities = () => {
                let upcoming = []
                try {
                    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
                    axios.get(`/api/activity/upcoming?${query}`)
                        .then(r => {
                            // Creating marker for each upcoming activities
                            r.data.upcoming.forEach(activity => {
                                let startingDate = moment(String(activity.starting_date)).format('DD/MM/YYYY')
                                let endingDate
                                if (activity.ending_date) {endingDate = moment(String(activity.ending_date)).format('DD/MM/YYYY')}

                                let activityDate = activity.ending_date && activity.ending_date != activity.starting_date ? `Du ${startingDate} au ${endingDate}` : `Le ${startingDate}`
                                let activityTime = activity.ending_time ? `de ${activity.starting_time} à ${activity.ending_time}` : `à ${activity.starting_time}`

                                let titre= `${activity.title} (${activity.type})`
                                let description= `
                                    <p style="overflow:overlay;height: 55px;">${activity.description}</p>
                                    <br>
                                    <span style="color: red;">${activityDate}<br>${activityTime}</span>
                                    <br>
                                    <a class="go" href="/activities">👁️<a>
                                    <button class="go direction activity ml-5" value="${activity.location.coordinates}">🏃🏻‍♂️<button>
                                `

                                let thisActivity = new Point(
                                    [
                                        activity.location.coordinates[0],
                                        activity.location.coordinates[1]
                                    ],
                                    titre,
                                    description
                                )
                                thisActivity.picture = activity.picture;

                                upcoming.push(thisActivity);
                            })
                            addMarker(upcoming, 'activity')
                            return upcoming
                        })
                } catch {
                    console.log('error')
                }
            }
            const fetchPoints = (url, color) => {
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        let lieux = []
                        data.records.forEach(elem => {

                            let titre=elem.fields.denomination
                            let description=elem.fields.remarques

                            if(elem.fields.denomination){
                                titre=elem.fields.denomination
                            }else if(elem.fields.affectation_denomination){
                                titre=elem.fields.affectation_denomination
                            }else if(elem.fields.nom){
                                titre=elem.fields.nom
                            }else{
                                titre=elem.datasetid
                            }

                            if(elem.fields.remarques){
                                description=elem.fields.remarques
                            }else if(elem.fields.periode){
                                description=elem.fields.periode
                            }else if(description=elem.fields.localisation){
                                description=elem.fields.localisation
                            }else{
                                description=elem.fields.village
                            }

                            description = `
                                <p>${description}</p>
                                <button class="go direction" value="${[
                                    elem.fields.point_geocodage[1],
                                    elem.fields.point_geocodage[0]
                                ]}">🏃🏻‍♂️<button>
                            `

                            lieux.push(new Point(
                                [
                                    elem.fields.point_geocodage[1],
                                    elem.fields.point_geocodage[0]
                                ],
                                titre,
                                description
                            ));
                        });
                        addMarker(lieux, color)
                        return lieux
                    })
            }
            
            mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

            const map = new mapboxgl.Map({
                container: 'map', // container ID
                style: 'mapbox://styles/mapbox/streets-v11', // style URL
                center: [4.986931618847292, 50.55097608920171], // starting position [lng, lat]
                zoom: 12 // starting zoom
            });

            map.on('load', ()=>{
                const nav = new mapboxgl.NavigationControl();
                map.addControl(nav, "top-right");

                const geolocate = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                    },
                    trackUserLocation: true
                });
                map.addControl(geolocate, "top-right")

                geojson['me'] = navigator.geolocation ? my_marker() : "la geolocalisation n'est pas activée"
                upcomingActivities()
                geojson["convivialite"] = fetchPoints(url_convivialite, 'green') 
                geojson["ecoles"] = fetchPoints(url_ecoles, 'purple') 
                geojson["livres"] = fetchPoints(url_livres, 'blue')  
                geojson["salles_communales"] = fetchPoints(url_salles_communales, 'grey')
                geojson["creches"] = fetchPoints(url_creches, 'pink')
                geojson["events"] = fetchPoints(url_events, 'black') 
                geojson["batiments_com"] = fetchPoints(url_batiments_com, 'orange')
            })

            ///// ADDED /////
            let directions = new MapboxDirections({
                accessToken: mapboxgl.accessToken,
                interactive: false,
                unit: "metric",
                language: "fr",
                placeholderOrigin: "Ton adresse de départ.",
                placeholderDestination: "L'adresse de ta destination."
            });

            map.addControl(directions,'bottom-right');

            window.addEventListener('click', (e) => {
                const target = e.target;

                if (target.classList.contains("direction")) {
                    //remove popup
                    const popup = document.getElementsByClassName('mapboxgl-popup');
                    if ( popup.length ) {
                        popup[0].remove();
                    }

                    //show directions
                    let user = geojson['me'].geometry.coordinates

                    directions.setOrigin(user);
                    directions.setDestination(target.value);
                }

                //toggle steps
                if (target.classList.contains("mapbox-directions-route-summary")) {
                    const directionsInstructions = document.querySelector(".mapbox-directions-instructions");

                    if (directionsInstructions.classList.contains("hide-directions-instructions")) {
                        directionsInstructions.classList.remove("hide-directions-instructions");
                    } else {
                        directionsInstructions.classList.add("hide-directions-instructions");
                    }
                }
            })
            //////////
        })
        return {
            geojson,
            avatar:'',
            directionVisible:true,
            filterVisible:true
        }
    },
    async created () {
        let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
        const user = await axios.get(`/api/user/myProfil?${query}`)
            .then(r => {
                const avatarUrl = r.data.avatarUrl

                this.avatar = avatarUrl
            })
    },
    methods:{
        filterActivity () {
            document.querySelectorAll('.my_activity').forEach(marker => {
                marker.style.visibility = document.querySelector('#filterActivity').checked == true ? "visible" : "hidden"
            })
        },
        filterBlue () {
            document.querySelectorAll('.blue').forEach(marker => {
                marker.style.visibility = document.querySelector('#filterBlue').checked == true ? "visible" : "hidden"
            })
        },
        filterPink () {
            document.querySelectorAll('.pink').forEach(marker => {
                marker.style.visibility = document.querySelector('#filterPink').checked == true ? "visible" : "hidden"
            })
        },
        filterPurple () {
            document.querySelectorAll('.purple').forEach(marker => {
                marker.style.visibility = document.querySelector('#filterPurple').checked == true ? "visible" : "hidden"
            })
        },
        filterOrange () {
            document.querySelectorAll('.orange').forEach(marker => {
                marker.style.visibility = document.querySelector('#filterOrange').checked == true ? "visible" : "hidden"
            })
        },
        filterGreen () {
            document.querySelectorAll('.green').forEach(marker => {
                marker.style.visibility = document.querySelector('#filterGreen').checked == true ? "visible" : "hidden"
            })
        },
        filterGrey () {
            document.querySelectorAll('.grey').forEach(marker => {
                marker.style.visibility = document.querySelector('#filterGrey').checked == true ? "visible" : "hidden"
            })
        },
        filterBlack () {
            document.querySelectorAll('.black').forEach(marker => {
                marker.style.visibility = document.querySelector('#filterBlack').checked == true ? "visible" : "hidden"
            })
        },
        visibilityDirection () {
            if(this.directionVisible){
                document.querySelector('.visibilityDirection').style='filter:drop-shadow(2px 4px 6px red)' 
                this.directionVisible =  false
                document.querySelector('.mapboxgl-ctrl-bottom-right').style='visibility:hidden'

            }else{
                document.querySelector('.visibilityDirection').style='filter:drop-shadow(2px 4px 6px green)'
                this.directionVisible = true
                document.querySelector('.mapboxgl-ctrl-bottom-right').style='visibility:visible'
            }
        },
        visibilityFilter(){
            if(this.filterVisible){
                document.querySelector('.visibilityFilter').innerText = "+" 
                document.querySelector('.visibilityFilter').style = "padding: 0px 5px" 
                document.querySelector('#filters').style = "visibility:hidden" 
                this.filterVisible = false
            }else{
                document.querySelector('.visibilityFilter').innerText = "-" 
                document.querySelector('.visibilityFilter').style = "padding: 0px 7px" 
                document.querySelector('#filters').style = "visibility:visible" 
                this.filterVisible = true
            }
        }
    }
  }
</script>

<style>
    .go{
        cursor: pointer;
        margin: 0px 5px 0px 5px;
        color: white;
        border: solid 1px #000000;
        border-radius: 5px;
        padding: 0px 5px;
        background-color: #e08b8b;
    }
    
    .go:hover{
        color: #e08b8b;
        background-color: white;
        cursor: pointer;
    }

    #map {
        height: 95vh;
    }
    #filters {
        margin: 10px;
        padding: 10px;
        position: absolute;
        z-index: 3;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.78);
        text-align:left;
    }

    #filters label {
        padding: 0px 10px;        
    }
    .my_marker {
        background-image: url('https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light');
        background-size: cover;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .my_activity {
        background-size: cover;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .blue::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -29px;
        left: -6px;
        border: 6px solid transparent;
        border-top: 17px solid blue;
    }

    .pink::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -29px;
        left: -6px;
        border: 6px solid transparent;
        border-top: 17px solid pink;
    }

    .purple::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -29px;
        left: -6px;
        border: 6px solid transparent;
        border-top: 17px solid purple;
    }

    .green::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -29px;
        left: -6px;
        border: 6px solid transparent;
        border-top: 17px solid green;
    }

    .black::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -29px;
        left: -6px;
        border: 6px solid transparent;
        border-top: 17px solid black;
    }

    .grey::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -29px;
        left: -6px;
        border: 6px solid transparent;
        border-top: 17px solid grey;
    }

    .orange::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -29px;
        left: -6px;
        border: 6px solid transparent;
        border-top: 17px solid orange;
    }
    .mapboxgl-popup {
        max-width: 200px;
        z-index: 5

    }

    .mapboxgl-popup-content {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
    }

    .mapboxgl-popup-close-button{
        margin: -5px 3px 0px 0px;
    }

    /* direction plugin */
    .mapbox-directions-steps {
        max-height: 200px;
    }

    .mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact, .mapboxgl-ctrl-bottom-left{
        visibility: hidden;
    }    

    .mapboxgl-ctrl-bottom-right{
        z-index:4
    }
    
    .visibilityDirection{
        filter: drop-shadow(2px 4px 6px green);
        width: 50px;
        cursor: pointer;
        position: absolute;
        top: 35%;
        right: 2%;
        z-index: 5;
        font-size: 1;
        font-size: 20px;    
    }

    .mapbox-directions-route-summary{
        cursor: pointer;
    }

    .hide-directions-instructions{
        visibility: hidden;
        height: 0;
    }
    .visibilityFilter{
        position: absolute;
        z-index: 4;
        padding: 0px 7px;
        border: solid 1px black;
        border-radius: 100%;
        background-color: white;
        margin: 0px 5px;
        font-size: 18px;
        font-weight: bolder;
        cursor: pointer;
    }

</style>
