<template>
    <div class="flex w-full items-center" id="app">
        <div class="w-full max-w-xl">
            <h1 class="font-bold text-5xl text-center text-indigo-700">
                Quizz
            </h1>
            <div class="bg-white p-9 rounded-lg shadow-lg w-full">
                <div v-if="index < allQuiz.length && allQuiz[index].quizStatus == true">
                    <div class="flex justify-center">
                        <img class="quiz_img" :src="allQuiz[index].picture" />
                    </div>
                    <br/>
                    <p class="text-2xl font-bold">
                        {{ allQuiz[index].question }}
                    </p>
                    <label
                        :for="key"
                        class="block mt-4 border border-gray-300 rounded-lg py-2 px-6 text-lg"
                        v-for="answer,key in allQuiz[index].answers"
                        :class="{'hover:bg-gray-100 cursor-pointer' : selectedAnswer == ''},
                    {'bg-red-200' : selectedAnswer == key},
                    {'bg-green-200' : key == allQuiz[index].correctAnswer && selectedAnswer != ''}"
                    >
                        <input
                            type="radio"
                            :id="key"
                            class="hidden"
                            :value="key"
                            @change="answered($event, allQuiz[index]._id)"
                            v-model="selectedAnswer"
                            :disabled="selectedAnswer != ''"
                        />
                        {{ answer }}
                    </label>
                    <div class="mt-6 flow-root">
                        <button
                            class="float-right px-5 py-2 bg-gray-600 text-white text-sm font-bold tracking-wide rounded-full"
                            v-show="selectedAnswer != '' && index < count-1"
                            @click="nextQuestion"
                        >
                            Suivant &gt;
                        </button>
                        <button
                            class="float-right px-5 py-2 bg-gray-600 text-white text-sm font-bold tracking-wide rounded-full"
                            v-show="selectedAnswer != '' && index == count-1"
                            @click="showResults"
                        >
                            Le quizz est terminé
                        </button>
                    </div>
                </div>
                <div v-else>
                    <h2 class="font-bold text-3xl">Résultats</h2>
                    <div class="flex justify-start space-x-4 mt-6">
                        <p>
                            bonnes réponses:
                            <span class="text-2xl text-green-700 font-bold"
                                >{{ correctAnswer }}</span
                            >
                        </p>
                        <p>
                            mauvaises reponses:
                            <span class="text-2xl text-red-700 font-bold"
                                >{{ wrongAnswer }}</span
                            >
                        </p>
                    </div>
                    <div class="mt-6 flow-root">
                        <button
                            class="float-right px-5 py-2 bg-gray-600 text-white text-sm font-bold tracking-wide rounded-full"
                            @click="resetQuiz"
                        >
                            recommencer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { VueAgile } from '@/assets/vue-agile'
    import axios from 'axios'

    export default {
        data() {
            return {
                allQuiz:[],
                index: 0,
                selectedAnswer: '',
                correctAnswer: 0,
                wrongAnswer: 0,
                count:0,
            }
        },
        methods: {
            answered(e, id) {
                this.selectedAnswer = e.target.value
                if(this.selectedAnswer == this.allQuiz[this.index].correctAnswer){
                    this.correctAnswer++
                    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
                    axios.get(`/api/quiz/correctAnswer/${id}?${query}`)
                        .then(r => {
                            if(r.data.success){
                                this.$toast.show(r.data.message, {
                                    type: "success",
                                    duration:1000
                                });
                            }else{
                                this.$toast.show(r.data.message, {
                                    type: "warning",
                                    duration:1000
                                });
                            }
                        })
                }else{
                    this.wrongAnswer++

                    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
                    axios.get(`/api/quiz/wrongAnswer/${id}?${query}`)
                        .then(r => {
                            if(r.data.success){
                                this.$toast.show(r.data.message, {
                                    type: "error",
                                    duration:2500
                                });
                            }else{
                                this.$toast.show(r.data.message, {
                                    type: "warning",
                                    duration:2500
                                });
                            }
                        })

                }
            },
            nextQuestion() {
                this.index++
                this.selectedAnswer = ''
            },
            showResults() {
                this.index++
            },
            resetQuiz() {
                this.index = 0
                this.selectedAnswer = ''
                this.correctAnswer = 0
                this.wrongAnswer = 0
            }
        },
        async created () {
            let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
            await axios.get(`/api/quiz?${query}`)
            .then(r => {
                r.data.quiz.map(question => {
                    if(question.quizStatus == true){
                        this.allQuiz.push(question)
                        this.count += 1
                    }
                })
            })
            .catch((e) => {
                localStorage.removeItem('token')
                this.$router.push('/login')
            })
        },
    }
</script>

<style>
    .quiz_img {
        max-height: 300px;
        margin:auto
    }

</style>
