<template>
 <cloudinaryModal @url="urlSubmited"></cloudinaryModal>
  <section class="c-lp add-slide-container">
    <h3 class="c-title">Suggestion</h3>

    <form @submit.prevent="addSlider">
    <div class="first flex justify-between mb-4">
      <div class="first__wrapper pr-4">
        <div class="selector-add-slide input-group flex mt-3">
          <select class="custom-select"
                  id="inputGroupSelect04"
                  required
                  v-model="slideProfile.type"
                  aria-label="Example select with button addon">
            <option selected>select Type</option>
            <option>Musique</option>
            <option>Jeux</option>
            <option>Сinéma</option>
            <option>Lecture</option>
            <option>Art</option>
            <option>Sport</option>
            <option>Technologie</option>
            <option>Mouvement de jeunesse</option>
            <option>Autre</option>

          </select>
        </div>

        <div class="first__text">
          <div class="up__input">
            <div class="flex items-center border-b border-teal-500 py-2">
              <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                     type="text"
                     v-model="slideProfile.title"
                     placeholder="Titre"
                     aria-label="Titre"
                     required
                     aria-describedby="basic-addon2">
            </div>
          </div>

          <div class="up__input">
            <div class="flex items-center border-b border-teal-500 py-2">
              <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                     type="text"
                     maxlength="50"
                     v-model="slideProfile.address"
                     placeholder="ex: 7, Rue Goffin"
                     aria-label="Lieu"
                     required
                     aria-describedby="basic-addon2">
            </div>
            <p v-if="errorAddress" style="color: red">{{errorAddress}}</p>
          </div>

        </div>
      </div>

      <div>
        <textarea v-model="slideProfile.description" class="form-textarea resize-none textarea-desc mt-1 block w-full" rows="3" placeholder="Description de la demande"></textarea>
      </div>

    </div>

    <div class="second flex justify-between">

      <div class="second-left flex flex-col">

        <div class="up__input">
          <div class="flex items-center border-b border-teal-500 py-2">
            <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                   type="email"
                   v-model="slideProfile.email"
                   placeholder="Email"
                   aria-label="Email"
                   required
                   aria-describedby="basic-addon2">
          </div>
        </div>
      </div>

      <div class="second-right flex justify-between flex-col">

        <div class="flex justify-between">
          <div class="up__input">
            <div class="flex items-center border-b border-teal-500 py-2">
              <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                     type="number"
                     v-model="slideProfile.gsm"
                     placeholder="Gsm"
                     aria-label="Gsm"
                     required
                     aria-describedby="basic-addon2"
                     maxlength="10">
            </div>
          </div>
        </div>

      </div>
    </div>

    <div>
      <div class="mt-1">
        <button id="toggleModal" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Séléctionner image
        </button>
        <img :src="url" id="pictureTag" class="pt-2">
      </div>
    </div>

    <div class="flex justify-center mt-3">
        <textarea required v-model="slideProfile.description" class="form-textarea textarea-mob mt-1 block w-full" maxlength="255" rows="3" placeholder="Description de la demande"></textarea>
    </div>

      <p v-if="emailErrorMessage" style="color: red" class="mt-4">{{emailErrorMessage}}</p>
      <p v-if="errorMessage" style="color: red" class="mt-4">{{errorMessage}}</p>

    <div class="flex justify-center mt-3">
      <button

              class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
        Suggérer
      </button>
    </div>
    <div class="flex justify-center mt-3">
      <button @click.prevent="back"

              class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
        Retour
      </button>
    </div>

    </form>
  </section>
</template>

<script>
import axios from 'axios'
import cloudinaryModal from '@/components/cloudinary/cloudinaryModal'
import mapboxgl from "mapbox-gl";

export default {
  name: 'AddSlide',
  components: {
    cloudinaryModal
  },
  data () {
    return {
      emailErrorMessage: '',
      errorMessage: '',
      slideProfile: {
        type:'',
        title:'',
        address:'',
        description:'',
        email:'',
        gsm:'',
        location:''
      },
      url: '',
      errorAddress: ''
    }
  },
  methods: {
   async addSlider () {
      if (this.validEmail(this.slideProfile.email)) {
        this.emailErrorMessage = ''
        this.slideProfile.picture = this.url

        // Check if an image is selected
        if ((document.getElementById("pictureTag").src).match(/\.(jpeg|jpg|gif|png)$/) == null) {
          this.errorMessage = 'Choisi une image.'
        } else {
          this.errorMessage = ''

          // Get the coordinates for the given address
          await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.slideProfile.address}%20Fernelmont%20Namur%20Belgium.json?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`)
            .then(r => {
              const result = r.data.features[0]
              // if found address
              if (result.place_name.includes("Fernelmont") && result.text != "Fernelmont") {
                const address = (result.address ? result.address + ", " : "") + result.text

                // if the adress is different that the one requested, ask the user
                if (address != this.slideProfile.address) {
                  let checkaddress = confirm(`Veux-tu dire "${address}"?`);

                  if (checkaddress == true) {
                    this.errorAddress = ""
                    this.slideProfile.address = address
                  } else {
                    this.errorAddress = "Adresse inconnue."
                  }
                } else {
                  // save coordinates
                  this.errorAddress = ""
                  this.slideProfile.location = result.geometry
                  let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
                  axios.post(`/api/activity/add_activity?${query}`, this.slideProfile)
                    .then(r => {
                      alert("Votre suggestion a bien été envoyée. Merci")
                      location.reload()
                    }
                    ).catch(e => {
                      this.errorMessage = e.response.data
                      // this.$router.push('/404')
                    })
                }
              } else {
                this.errorAddress = "Adresse inconnue."
              }
            })
        }
      } else {
        this.emailErrorMessage = 'Email is wrong'
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    back () {
      location.reload()
    },
    urlSubmited (url) {
      this.url = url
    }
  }
}
</script>

<style scoped>
  .add-slide-container {
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
  }

  .up__input {
    max-width: 180px;
    width: 100%;
    padding-left: 1px;
  }

  .up__input-time {
    max-width: 80px;
    width: 100%;
  }

  .textarea-mob,
  .textarea-desc {
    max-width: 180px;
    height: 140px;
  }

  .textarea-mob {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .textarea-mob {
      display: block;
    }

    .textarea-desc {
      display: none;
    }

    .first__wrapper,
    .selector-add-slide {
      width: 100%;
    }

    .first__wrapper {
      padding-right: 0;
    }

    .selector-add-slide {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }

    .first__text {
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 480px) {
    .up__input {
      max-width: 140px;
    }

    .c-lp.add-slide-container {
      padding: 0 0.5rem;
    }

    .c-title {
      margin-bottom: 0px;
    }
  }
</style>
