<template>
  <section class="inf-p c-lp">
    <h3 v-if="cardArray.length" class="c-title">Participe à la prochaine activité</h3>

    <div v-if="cardArray.length" class="slide-container">
      <agile ref="carousel" :options="myOptions" class="desc">
        <div class="slide" v-for="(card, i) in activityStatus" :key="i">
          <div class="slide-wrapper" @click="$refs.carousel.goTo(i)"
               :style="{'background-image': card.picture ? `url(${card.picture})` : 'null'}">
            <div class="slide-head"></div>
            <div class="slide-text">
              <div class="slide-wrapper-header">
                <h3 v-if="card.title" class="slide-title">{{ card.title  }}</h3>
                <p>{{ card.type }}</p>
              </div>
              <template v-if="!card.active">
                <div class="date-div">
                  <h4 v-if="card.starting_date"> Le {{ formatDateonly(card.starting_date ) }} à {{ card.starting_time }}</h4>
                </div>
                <h4 v-if="card.address">{{ card.address }}</h4>
                <p v-if="card.description" class='scroll'>{{ card.description }}</p>
                <h1> Déjà {{card.participants.length }} participant(e)s </h1>

               <div class="flex justify-center items-center pt-3">
                  <div class="flex relative items-center">
                    <button @click="participer(card)" class="cb-btn bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded" style="font-size: 14px">
                      Participer
                    </button>
                    <a @click="showChat(card)" style='font-size: 25px' class="cb-btn-chat ml-10 inline-block align-baseline font-bold text-sm absolute">💬</a>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="chat">
                  <ul class="chat-wrapper">
                    <li class="chat-item flex" v-for="(comment, i) in card.comments" :key="i">
                      <img class="chat-user-icon self-center ml-2 mr-2" :src="comment.created_by.avatarUrl" />
                      <div class="flex flex-col">
                        <div class="chat-user-name"><b>{{ comment.created_by.pseudo }}</b></div>
                        <span style="font-size:8px">{{formatDateFromNow(comment.create_at)}}</span>
                        <span @click="deleteComment(card, comment)" class='deleteComment'>x</span>
                        <div class="chat-user-comment">{{ comment.comment }}</div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="chat-input">
                  <div>
                    <label class="relative flex justify-center">
                      <input v-model="message.text" type="text" class="send-input shadow appearance-none border rounded w-full mb-2 py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="écris ton message ici..."/>
                      <svg @click="sendMessageInChat(card)" class="send-message absolute" enable-background="new 0 0 24 24" height="128" viewBox="0 0 24 24" width="128" xmlns="http://www.w3.org/2000/svg"><path d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z"/><path d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z"/></svg>
                    </label>
                    <svg @click="closeChat(card)" class="close-chat-btn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" xmlns:v="https://vecta.io/nano"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984s2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028s-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
                  </div>
                </div>
              </template>

            </div>

            <div class="slide-footer absolute">
              <ShareNetwork
                class="facebook-share-link hover:bg-white"
                network="facebook"
                url="http://www.fernelmove.be/activities/"
                title=""
                description=""
                quote=""
                hashtags="fernelmove"
              ></ShareNetwork>
            </div>
          </div>
        </div>
      </agile>
    </div>
    <div v-else class="bienvenue">
      Bienvenue sur Fernelmove.be
    </div>
  </section>
  <section v-if="cardArray.length" class="mob-slider">
    <agile class="agile-mobile mob"
           :slides-to-show="1"
           :dots="false"
           :nav-buttons="false">
      <div class="slide-mob" v-for="(mobCard, iMobCard) in activityStatus" :key="iMobCard"
           :style="{'background-image': mobCard.picture ? `url(${mobCard.picture})` : 'null'}"
      >
        <div class="mob-s__wrapper">
          <div class="mob-s__wrapper-header">
            <div class="title">
              <h1 class="pb-5">Participe à la prochaine activité</h1>
            </div>
            <h3 v-if="mobCard.title" class="slide-title">{{ mobCard.title }}</h3>
            <p>{{ mobCard.type }}</p>
          </div>

          <template v-if="!mobCard.active">
            <div class="date-div">
              <h4 v-if="mobCard.starting_date"> Le {{ formatDateonly(mobCard.starting_date ) }} à {{ mobCard.starting_time }}</h4>
            </div>

            <h4 v-if="mobCard.address">{{ mobCard.address }}</h4>
            <p v-if="mobCard.description" class='scroll'>{{ mobCard.description }}</p>
            <h1> Déjà {{mobCard.participants.length }} participant(e)s </h1>


            <div class="flex justify-center items-center mob-s__btn">
              <div class="flex relative items-center">
                <button @click="participer(mobCard)" class="relative bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded" style="font-size: 14px">
                  Participer
                </button>
                <a @click="showChat(mobCard)" style='font-size: 25px' class="cb-btn-chat ml-2 inline-block align-baseline absolute">💬</a>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="chat">
              <ul class="chat-wrapper">
                <li class="chat-item flex" v-for="(comment, i) in mobCard.comments" :key="i">
                  <img class="chat-user-icon self-center ml-2 mr-2" :src="comment.created_by.avatarUrl" />
                  <div class="flex flex-col">
                    <div class="chat-user-name"><b>{{ comment.created_by.pseudo }}</b></div>
                    <span style="font-size:8px">{{formatDateFromNow(comment.create_at)}}</span>
                    <span @click="deleteComment(mobCard, comment)" class='deleteComment'>x</span>
                    <div class="chat-user-comment">{{ comment.comment }}</div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="chat-input">
              <div style="padding: 0 .5rem">
                <label class="relative flex justify-center">
                  <input v-model="message.text" type="text" class="send-input shadow appearance-none border rounded w-full mb-2 py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="écris ton message ici..."/>
                  <svg @click="sendMessageInChat(mobCard)" class="send-message absolute" enable-background="new 0 0 24 24" height="128" viewBox="0 0 24 24" width="128" xmlns="http://www.w3.org/2000/svg"><path d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z"/><path d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z"/></svg>
                </label>
                <svg @click="closeChat(mobCard)" class=" close-chat-btn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" xmlns:v="https://vecta.io/nano"><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984s2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028s-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
              </div>
            </div>
          </template>

          <div class="slide-footer absolute">
            <ShareNetwork
              class="facebook-share-link mob hover:bg-white"
              network="facebook"
              url="https://www.fernelmove.be/activities"
              title=""
              description=""
              quote=""
              hashtags="fernelmove"
            ></ShareNetwork>
          </div>

        </div>
      </div>
    </agile>
  </section>
</template>

<script>
import { VueAgile } from '@/assets/vue-agile'
import axios from 'axios'
import moment from "moment";

export default {
  name: 'Activities',
  components: {
    agile: VueAgile
  },
  data () {
    return {
      message: {
        user: '',
        text: ''
      },
      cardArray: [],
      myOptions: {
        navButtons: false,
        slidesToShow: 3,
        swipeDistance: 100,
        infinite: true,
        centerMode: true
      },
      count: 0,
    }
  },
  async created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    await axios.post(`/api/activity?${query}`, {
        activity: 'activity'
    })
      .then(r => {
        this.cardArray = r.data.activity
        this.cardArray.forEach(function (item) {
          item.active = false
        })
      })
      .catch((e) => {
        localStorage.removeItem('token')
        this.$router.push('/login')
      })
  },
  methods: {
    formatDateonly (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    formatDateFromNow (value) {
      if (value) {
        return moment(String(value)).locale("fr").fromNow()
      }
    },
    formatTimeOnly (value) {
      if (value) {
        return moment(String(value)).format('HH:MM')
      }
    },
    showChat (card) {
      card.active = true
    },
    closeChat (card) {
      card.active = false
    },
    async deleteComment(card,comment){
      if(confirm('êtes-vous sur de vouloir supprimer ce commentaire?')==true){
        let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
        const deleteComment = await axios.delete(`/api/activity/comment/${comment._id}?${query}`, {data:{author:comment.created_by._id}})
          .then(r => {
            if(r.data.success){
              card.comments.splice(card.comments.indexOf(comment),1)
              this.$toast.show(r.data.message, {
                  type: "success",
                  duration:2500
              })
            }else{
              this.$toast.show(r.data.message, {
                  type: "error",
                  duration:2500
              })
            }
          })
      }
    },
    async sendMessageInChat (card) {
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      const user = await axios.post(`/api/activity/add_comment/${card._id}?${query}`, {comment:this.message.text})
        .then(r => {
          const message = {
            created_by:{
              pseudo : r.data.pseudo,
              avatarUrl: r.data.avatarUrl
            },
            comment: r.data.comment.comment,
            create_at:r.data.comment.create_at
          }
          card.comments.push(message)
          this.message.text = ""
        })
    },
    async addUser (card) {
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      const user = await axios.get(`/api/user/myProfil?${query}`)
      card.participants.push(user.data.user[0])
      await axios.put(`/api/activity/${card._id}?${query}`, card)
        .catch(e => console.log(e))
    },
    async participer (card) {
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      await axios.put(`/api/activity/participer/${card._id}?${query}`)
        .then((res)=>{
          if(res.data.success){

            this.$toast.show('votre participation a bien été anoncée', {
                type: "success",
                duration:2500
            }) 
            card.participants.push(1)
          }else{
            this.$toast.show('vous avez annulé votre participation', {
                type: "error",
                duration:2500
            })
            card.participants.shift(1)
          }
        })
        .catch(e => console.log(e))
    }
  },
  computed: {
    activityStatus: function () {
      return this.cardArray.filter(card => {
        return card.activityStatus
      })
    }
  }
}
</script>

<style scoped>
  .bienvenue{
    text-align: center;
    color: red;
    font-family: acier-bat-noir, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    margin-top: 7px;
  }
  .scroll{
    height:100px;
    overflow:auto;
  }
  .deleteComment {
    cursor:pointer;
    position: absolute;
    right: 15px;
  }

  .date-div {
    width:100%;
    margin-bottom : 0;
  }

  .mob-slider {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100vh;
    display: none;
  }

  /* .send-input {
    max-width: 350px;
    width: 100%;
    padding-right: 55px;
  } */

  .send-message {
    max-width: 30px;
    max-height: 30px;
    width: 100%;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .agile-mobile {
    width: 100%;
    height: 100vh;
  }

  .mob-s__wrapper {
    color: white;
    background: rgba(0,0,0,0.5);
    border-top: 1px solid #46505e;
    border-bottom: 1px solid #46505e;
    padding: 2rem 1rem;
    display: flex;
    width: 100%;
    align-self: flex-end;
    margin-bottom: 40%;
    position: relative;
    flex-direction: column;
    min-height: 68%;
    max-height: 68%;
  }
  .mob-s__wrapper-header h3 {
    background-color: white;
  }
  .mob-s__wrapper-header p {
    background-color: red;
    font-weight: 700;
  }
  .slide-wrapper-header h3 {
    background-color: white;
    text-align: center;
  }
  .slide-wrapper-header p {
    background-color: red;
    font-weight: 700;
    text-align: center;
  }
  h1 {
    text-align: center;
    color: white;
    font-family: acier-bat-noir, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
    /* text-underline-offset: 6px;
    text-overline-offset: 6px; */
    margin-top: 7px;
  }

  .mob-s__btn {
    flex: 1 1 auto;
  }

  .slide-mob {
    color: #000;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }

  .slide-wrapper {
    border: 1px solid #46505e;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }

  .slide-container {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 120px;
    overflow-x: hidden;
  }

  .slide-container.mobile {
    display: none;
  }

  .slide-footer {
    right: 10px;
    bottom: 10px;
    display: flex;
    /*background-color: #fff;*/
    border-radius: 6px;
    padding: 2px;
  }

  .slide-text {
    color: white;
    background: rgba(0,0,0,0.5);
    border-top: 1px solid #46505e;
    border-bottom: 1px solid #46505e;
    padding: 1rem;
    margin: 4rem 0;
    /*background-color: #fff;*/
  }

  .slide-text > p {
    font-size: 14px;
  }

  .slide-text {
    text-align: left;
  }

  .slide-text > h4 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .come-back {
    position: relative;
  }

  .come-back > svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    transform: scale(-1, 1);
  }

  ::v-deep .agile__dot.agile__dot--current,
  ::v-deep .agile__dot {
    width: 15px;
    height: 15px;
    margin: 0 .5rem;
    border-radius: 50%;
    border: 2px solid #46505e;
  }

  ::v-deep .agile__dot.agile__dot--current {
    background-color: #46505e;
  }

  .cb-btn-chat {
    font-size: 12px;
    right: -100%;
    cursor: pointer;
  }

  .close-chat-btn {
    width: 27px;
    background: white;
    color: white;
    display: block;
    padding: 0.3rem;
    /*right: 15px;*/
    /*top: 20px;*/
    border-radius: 20px;
    cursor: pointer;
    margin: auto;
  }

  .chat {
    height: 340px;
    overflow: auto;
    background: rgba(0, 0, 0, 0.5);
    padding: 0 .5rem;
    border-radius: 6px 6px 0 0;
    position: relative;
  }
  .chat-input {
    background: rgba(0,0,0,0.5);
    border-radius: 0 0 6px 6px;
  }

  .chat-input > input {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
  }

  .chat-user-icon {
    max-width: 30px;
    max-height: 30px;
    width: 100%;
  }

  .chat-item {
    border: 1px solid black;
    border-radius: 6px;
    margin: 0.5rem 0;
  }

  .chat-user-name {
    font-size: 14px;
  }

  .chat-user-comment {
    font-size: 12px;
    padding-bottom: 10px;
  }

  .chat-item:nth-of-type(odd) {
    background-color: antiquewhite;
    color: black;
  }

  .chat-item:nth-of-type(even) {
    background-color: indianred;
    color: white;
  }

  @media screen and (max-width: 2560px) {
    .slide-container {
      max-width: 900px;
    }

    ::v-deep .slide.agile__slide {
      width: 300px!important;
    }

    /* .send-input {
      max-width: 250px;
    } */

    .cb-btn {
      margin-bottom: 20px;
    }

    .cb-btn-chat {
      right: initial;
      bottom: -10px;
    }

  }

  @media screen and (max-width: 991px) {
    ::v-deep .slide-mob.agile__slide.agile__slide--active {
      transform: scale(1) translateY(0)!important;
    }

    .slide-container {
      max-width: 735px;
    }

    ::v-deep .slide.agile__slide {
      width: 245px!important;
    }

    /* .send-input {
      max-width: 195px;
    } */
  }

  @media screen and (min-width: 767px) {
    ::v-deep .agile__slide--active {
      transform: scale(1.2) translateY(-12%)!important;
      z-index: 2;
    }

    .slide-wrapper {
      min-height: 470px;
      position: relative;
      z-index: 1;
    }

    ::v-deep .agile__list {
      overflow: initial!important;
    }

    ::v-deep .agile__actions {
      padding-top: 2rem;
    }

    ::v-deep .slide.agile__slide,
    ::v-deep .agile__slides {
      min-height: 470px!important;
    }
  }

  @media screen and (max-width: 767px) {
    .desc {
      display: none;
    }

    .mob-slider {
      display: block;
    }

    .c-title {
      display: none;
    }

    ::v-deep .agile__track {
      height: 100vh;
    }

    ::v-deep .agile__list {
      height: 100vh;
    }

    .cb-btn-chat {
      right: -100%;
      bottom: initial;
    }

    /* .chat,
    .chat-input {
      max-width: 365px;
      margin: 0 auto;
      width: 100%;
    }

    .send-input { 
       max-width: 350px; 
    } */
  }

  /* @media screen and (max-width: 420px) {
    .chat,
    .chat-input {
      max-width: 280px;
      margin: 0 auto;
      width: 100%;
    }

    .send-input {
      max-width: 265px;
    }
  } */
  @media screen and (max-height: 580px) {
    .mob-s__wrapper {
      margin-bottom: 20%;
    }
  }
   
</style>
