<template>
  <div class="privacy-policy">

  <h3 class="c-title text-center">
    Charte relative à l’utilisation des données personnelles par la Commune de Fernelmont. </h3>

  <p>
    La présente Charte est établie par l’Administration communale de Fernelmont, située rue Goffin, 2 à 5380 Fernelmont,
    qui peut être contactée au 081/83 02 50 ; par fax au 081/83 02 70 et par courriel à l’adresse
    <a class="inline-block align-baseline font-bold text-gray-700 hover:text-gray-900 mb-2"
       href="mailto:info@fernelmont.be">info@fernelmont.be</a>. </p>

  <p>
    La présente Charte a pour but de vous informer de la manière dont nous récoltons et traitons vos données à caractère
    personnel. </p><br>
  <p>
    La présente Charte s’inscrit dans le souhait du responsable du traitement, d’agir en toute transparence, dans le
    respect de la Loi du 30 juillet 2018, relative à la protection des personnes physiques à l'égard des traitements de
    données à caractère personnel et du Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016
    relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la
    libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des
    données). </p><br>
  <p>
    Le responsable du traitement porte une attention particulière à la protection de la vie privée et s’engage par
    conséquent à prendre les précautions raisonnables requises pour protéger les données à caractère personnel récoltées
    contre la perte, le vol, la divulgation ou l’utilisation non autorisée.
    Dès lors, sauf limitations posées par la législation applicable, le Règlement général sur la protection des données
    ou « RGPD », relatif à vos données personnelles vous accorde les droits suivants : </p><br>
  <ul>
    <li>Droit d'accès : le droit d'être informé et de demander l'accès aux données personnelles que nous traitons, (Art.
      15) ;
    </li>
    <li>Droit de rectification : le droit de nous demander de modifier ou de mettre à jour vos données personnelles
      lorsqu'elles sont inexactes ou incomplètes, (Art. 16) ;
    </li>
    <li>Droit d'effacement : le droit de nous demander de supprimer définitivement vos données personnelles, (Art. 17)
      ;
    </li>
    <li>Droit de limitation : le droit de nous demander d'arrêter temporairement ou définitivement le traitement de tout
      ou partie de vos données personnelles, (Art. 18) ;
    </li>
    <li>Obligation du responsable du traitement de vous envoyer une notification si vous en faites demande à chaque fois
      que vos données à caractère personnel ont été communiquées, rectifiées, effacées ou ont subis une limitation (Art.
      19) ;
    </li>
    <li>Droit à la portabilité des données : le droit de demander une copie de vos données personnelles au format
      électronique et le droit de transmettre ces données personnelles pour une utilisation par un tiers (Art. 20) ;
    </li>
    <li>Droit d’opposition (Art. 21) :</li>
    <ul>
      <li>le droit de refuser à tout moment le traitement de vos données personnelles pour des raisons personnelles ;
      </li>
      <li>le droit de refuser le traitement de vos données personnelles à des fins de prospection ;</li>
    </ul>
    <li>Droit de ne pas être soumis à la prise de décision automatisée : le droit de ne pas être soumis à une décision
      basée uniquement sur la prise de décision automatisée, y compris le profilage, dans le cas où la décision aurait
      un effet juridique sur vous ou produirait un effet significatif similaire (Art. 22).
    </li>
  </ul>
  <br>
  <br>

  <h3 class="c-title ">Chapitre I. QUELLES DONNEES COLLECTONS-NOUS ? </h3>
  <p>La fonction première d’une administration communale est d’offrir un service optimal aux citoyens. Dans ce cadre, la
    loi autorise l’accès et la collecte d’un certain nombre d’informations sur les habitants de la commune. Chaque
    citoyen désireux de connaître les informations détenues par la commune est invité à nous contacter par écrit selon
    les méthodes précisées au chapitre 10. </p><br>
  <p>Il est possible que le responsable du traitement soit également amené à récolter des données à caractère non
    personnel qui ne permettent pas d’identifier directement ou indirectement une personne en particulier. Elles
    pourront dès lors être utilisées à quelques fins que ce soit. </p><br>
  <p>Dans l’hypothèse où des données à caractère non personnel sont combinées à des données à caractère personnel, de
    sorte qu’une identification des personnes concernées devient possible, ces données seront traitées comme des données
    à caractère personnel jusqu’à ce que leur rapprochement avec une personne particulière soit rendu impossible. </p>
  <br>
  <h3 class="c-title ">Chapitre 2. METHODE DE COLLECTE DES DONNEES </h3>
  <p>Le responsable du traitement collecte vos données à caractère personnel de trois façons : </p>
  <ul style="list-style: initial;">
    <li>1) lorsque vous vous rendez dans les locaux de l’administration communale pour une demande de service (type
      changement d’adresse)
    </li>
    <li>2) en cas de demande(s) via le guichet électronique, par courriel ou par courrier</li>
    <li>3) par le biais de cookies, via le site internet. (Voir la politique à cet égard au chapitre 7)</li>
  </ul>
  <br>

  <h3 class="c-title ">Chapitre 3. QUELLES SONT LES FINALITES DU TRAITEMENT DE VOS DONNEES ? </h3>
  <p>Les données à caractère personnel ne sont recueillies et traitées qu’aux seules fins mentionnées ci-dessous : </p>
  <ul>
    <li>Tous les traitements liés à la bonne exécution de notre mission d’intérêt public.</li>
    <li>Tous les dossiers concernant les activités communales sont traités par les membres du personnel de
      l’administration communale ¨de Fernelmont chargés de la bonne exécution de la mission d’intérêt public qui lui
      incombe.
    </li>
  </ul>
  <br>
  <h3 class="c-title ">Chapitre 4. DUREE DE CONSERVATION DES DONNEES PERSONNELLES </h3>
  <p>Le responsable du traitement ne conserve les données à caractère personnel que pendant le temps raisonnablement
    nécessaire aux finalités poursuivies et en accord avec les exigences légales et réglementaires. </p><br>
  <p>Les données recueillies suite à une question de votre part sont conservées le temps de vous répondre. </p><br>
  <p>Les données recueillies par les cookies, concernant les données de trafic notamment, sont conservées pendant <b>13
    mois</b> maximum.</p>
  <p>Au terme de la période de conservation, le responsable du traitement met tout en œuvre pour s’assurer que les
    données personnelles ont bien été rendues indisponibles. </p><br>
  <h3 class="c-title ">Chapitre 5. VOS DROITS A L’EGARD DE VOS DONNEES PERSONNELLES </h3>
  <p>Moyennant demande écrite datée et signée envoyée à l’adresse visée au chapitre 10 « COMMENT NOUS CONTACTER » de la
    présente Charte, et en justifiant de votre identité (en joignant une copie de votre carte d’identité/passeport),
    vous avez le droit : </p>
  <ul>
    <li>d’obtenir gratuitement la communication écrite ou une copie de vos données à caractère personnel qui ont été
      recueillies par le responsable du traitement ;
    </li>
    <li>d’obtenir gratuitement, dans les meilleurs délais et au plus tard dans un délai d’un mois, la rectification de
      vos données à caractère personnel qui seraient inexactes, incomplètes ou non pertinentes, ainsi que les compléter
      si elles s’avèrent incomplètes ;
    </li>
    <li>
      de limiter le traitement de vos données à caractère personnel, notamment dans les cas suivants :
    </li>
    <ul style="list-style: initial;">
      <li>1) lorsque vous contestez l’exactitude d’une donnée et uniquement le temps que nous puissions contrôler
        celle-ci
      </li>
      <li>2) lorsque, bien que n’étant plus nécessaire à la poursuite des finalités du traitement, vous en avez besoin
        pour la constatation, l’exercice ou la défense de ses droits en justice
      </li>
    </ul>
    <li>d’obtenir l’effacement de vos données à caractère personnel, notamment lorsque l’un des motifs suivants
      s’applique :
    </li>
    <ul>
      <li>vos données ne sont plus nécessaires au regard des finalités du traitement ;</li>
      <li>vous avez retiré votre consentement à ce que vos données soient traitées et il n’existe pas d’autre fondement
        juridique au traitement.
      </li>
    </ul>
    <li>de recevoir gratuitement vos données à caractère personnel dans un format structuré, couramment utilisé et
      lisible par machine, en vue notamment de les transmettre à un autre responsable du traitement lorsque le
      traitement est fondé sur le consentement de la personne ou sur un contrat conclu entre ce dernier et le
      responsable du traitement
    </li>
    <li>vous opposer au traitement de vos données personnelles pour des raisons tenant à votre situation particulière
      ;
    </li>
    <li>introduire une réclamation auprès de l’autorité de contrôle, à savoir l’Autorité de Protection des données (voir
      le chapitre 9)
    </li>
  </ul>
  <p>Dans les mêmes conditions et selon les mêmes modalités, vous avez le droit d’obtenir du responsable du traitement
    que les données à caractère personnel vous concernant soient transmises directement à un autre responsable du
    traitement de données à caractère personnel, pour autant que cela soit techniquement possible. </p><br>
  <h3 class="c-title ">Chapitre 6. QUI A ACCES A VOS DONNEES ?</h3>
  <p>Les données récoltées sont destinées au responsable du traitement à savoir l’administration communale de
    Fernelmont. </p>
  <p>Les données récoltées ne sont pas divulguées à un tiers sans consentement préalable de votre part sauf si cela est
    nécessaire pour répondre à vos demandes ou encore si nous sommes légalement tenus de le faire. </p>
  <p>Aucun transfert de données à caractère personnel hors de l’Union européenne n’est et ne sera effectué. </p><br>
  <h3 class="c-title ">Chapitre 7. UTILISATION ET GESTION DES « COOKIES » </h3>
  <p class="pb-2"><b>A. Principes généraux </b></p>
  <p>Comme la plupart des sites web, nous plaçons de petits fichiers de données appelés « cookies » sur votre
    ordinateur. Ils nous aident à rendre votre visite plus agréable ainsi qu’à mieux adapter le site à vos besoins.
    Certains sont nécessaires au bon fonctionnement du site web, tandis que d’autres permettent d’améliorer votre
    expérience. </p><br>
  <p>En visitant et en utilisant le site web, vous marquez expressément votre accord sur la gestion des cookies décrite
    ci-après. </p><br>
  <p class="pb-2"><b>B. Définition des « cookies » </b></p>
  <p>Un « cookie » est un fichier de données ou de textes que le serveur d’un site web que vous visitez installe de
    manière temporaire ou permanente sur l’équipement de l’utilisateur (disque dur de l’ordinateur, tablette, smartphone
    ou tout autre appareil similaire) par l’intermédiaire de son navigateur. </p><br>
  <p>Les cookies retiennent un certain nombre d’information, comme les préférences linguistiques des visiteurs, de sorte
    que vous ne devez pas les repréciser lors d’une visite ultérieure. D’autres cookies peuvent aussi être créés pour
    des raisons techniques ou encore recueillent, anonymement, des statistiques quant aux utilisateurs d’un site
    web. </p><br>
  <p>Il n’est hélas pas possible techniquement d’enregistrer en une seule opération vos préférences sur différents
    ordinateurs, smartphones ou tablettes, ni dans différents navigateurs internet. Vos préférences devront donc être
    enregistrées chaque fois que vous utiliserez un navigateur internet pour la première fois ou lorsque vous aurez
    précédemment effacé les cookies. </p><br>
  <h3 class="c-title ">Chapitre 8. COMMENT SECURISONS-NOUS VOS DONNEES PERSONNELLES ?</h3>
  <p>Nous mettons en œuvre les mesures techniques et organisationnelles appropriées afin de garantir un niveau de
    sécurité du traitement et des données récoltées au regard des risques présentés par le traitement et de la nature
    des données à protéger adaptés au risque. Nous tenons compte de l’état des connaissances, des coûts de mise en œuvre
    et de la nature, de la portée, du contexte et des finalités du traitement ainsi que des risques pour vos droits et
    libertés. </p><br>
  <p>Nous avons mis en place des mesures de sécurité appropriées pour protéger et éviter la perte, l’usage abusif ou
    l’altération des informations reçues sur notre site web. </p><br>
  <h3 class="c-title ">Chapitre 9. DEPOSER UNE PLAINTE OU UNE RECLAMATION</h3>
  <p>Si vous souhaitez réagir à l’une des pratiques décrites dans la présente Charte, l’article 15 du RGPD octroie le
    droit de : nous contacter en écrit aux adresses précisées au chapitre 10 « Comment nous contacter ». </p>
  <p>Vous pouvez introduire une réclamation auprès de l’instance de contrôle territoriale, l’Autorité de Protection des
    Données à l’adresse suivante : </p>
  <p>Autorité de protection des données (APD) </p>
  <p>Rue de la Presse 35 </p>
  <p>1000 BRUXELLES </p>
  <p>Tél. : +32 (0)2 274.48.00</p>
  <p>Fax : +32 (0)2 274.48.35</p>
  <p>Courriel : <a class="inline-block align-baseline font-bold text-gray-700 hover:text-gray-900 mb-2"
                   href="mailto:contact@apd-gba.be">contact@apd-gba.be</a></p><br>
  <h3 class="c-title">Chapitre 10. COMMENT NOUS CONTACTER ?</h3>
  <p>Pour toute question et/ou réclamation concernant la gestion de vos données personnelles, ou quant au caractère
    clair et accessible de la présente Charte, vous pouvez nous contacter par lettre datée et signée aux adresses
    suivantes :</p>
  <p>Par courriel : <a class="inline-block align-baseline font-bold text-gray-700 hover:text-gray-900 mb-2"
                       href="mailto:info@fernelmont.be">info@fernelmont.be</a></p>
  <p>Par courrier : Administration communale de Fernelmont ; rue Goffin, 2 à 5380 Fernelmont. </p>
  <p>Vous pouvez contacter également le Délégué à la Protection des Données (DPD):</p>
  <p>Par courriel: <a class="inline-block align-baseline font-bold text-gray-700 hover:text-gray-900 mb-2"
                      href="mailto:dpd@dpd12.be">dpd@dpd12.be</a></p>
  <h3 class="c-title">Chapitre 11. MODIFICATION DE LA PRESENTE CHARTE </h3>
  <p>Nous nous réservons le droit de modifier à tout moment les dispositions de la présente Charte. Les modifications
    seront publiées avec un avertissement quant à leur entrée en vigueur. </p><br>
  <h3 class="c-title">Chapitre 12. DROIT APPLICABLE ET JURIDICTION COMPETENTE</h3>
  <p>La présente Charte est régie par le droit belge.</p>
  <p>Tout litige relatif à l’interprétation ou l’exécution de la présente Charte sera soumis au droit belge et relèvera
    de la compétence exclusive des tribunaux de l’arrondissement judiciaire de Namur.</p>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>
.privacy-policy {
  max-width: 1140px;
  text-align: left;
  margin: 0 auto;
  padding: 2rem 1rem;
}

ul {
  list-style: initial;
  margin: initial;
  padding-left: 40px;
  padding-top: 10px;
}
</style>
